export default {
    card: {
        bg: 'white',
        boxShadow: 'base',
        borderRadius: 'base',
        borderStyle: "solid",
        borderColor: "cardBorder",
        borderWidth: "1px",
        p: 5,
    },
    aside: {
        bg: 'cardBackground.500',
        border: '1px solid',
        borderRadius: 'base',
        borderColor: 'asideBorder',
        p: 5,
    },
};
