import {createColorScheme} from "frontend/theme/utils";
// Gets the anvil styles for the current page from the document head. Defined in `style_tags.page_style_config`.
const pageStyles = JSON.parse(document.getElementById('page-style-config').textContent);

for (let [key, value] of Object.entries(pageStyles.colors)) {
    // create color schemes for every color coming from Anvil's pageStyles.
    pageStyles.colors[key] = createColorScheme(value)
}

export default {
    ...pageStyles,
    breakpoints: {
        // These are the breakpoints as defined in `styles/static_src/sass/base.scss`.
        // Using em here because px causes the issue described here: https://github.com/chakra-ui/chakra-ui/issues/2188
        base: "0em",  // Chakra always adds a base breakpoint for 0. This is equivalent to Foundation's small.
        md: "48em",
        lg: "64em",
        xl: "90.0625em",
    },
};
