import $ from 'jquery';

/** Register a callback function to be called when the elements specified by `elementFilter` get added to the page.
 *
 * @param elementFilter - A jquery selector used to check if the elements that you are looking for were added.
 * @param callback - Function to be called when the specified element is added to the page. The only parameter passed
 *  is the element that was added.
 * @param observerOptions - JSON object specifying the MutationObserverInit configuration values to use. Defaults to
 *  using childList and subtree.
 * @param callOnce - Whether or not the callback should only be run for the first matching element.
 */
export default function mutationObserver(elementFilter, callback, observerOptions, callOnce=false) {
    // Default the observer options to watch when child nodes are added and when targets descendants are changed.
    observerOptions = typeof observerOptions === 'undefined' ? {
        childList: true,
        subtree: true,
    } : observerOptions;
    // MutationObserver is the future, but doesn't work in IE10 so fall back to the deprecated DOMNodeInserted event
    if ('MutationObserver' in window) {
        let observer = new MutationObserver((mutations) => {

            for (let mutation of mutations) {
                let foundMatch = false;
                let matchedElements = $(mutation.addedNodes).find(elementFilter).addBack(elementFilter);

                for (let element of matchedElements) {
                    callback(element, mutation);
                    foundMatch = true;
                }

                if(callOnce && foundMatch)
                  break;
            }
        });
        observer.observe(document.body, observerOptions);
    }
    else {
        $(document).on('DOMNodeInserted', (e) => $(e.target).find(elementFilter).addBack(elementFilter).each((index, element) => callback(element)));
    }
}
