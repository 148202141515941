const baseStyle = {
    fontWeight: 'normal',
    textAlign: 'center',
    border: '1px solid transparent',
    margin: 'auto',
}

// The default properties
const defaultProps = {
    // Make buttons large by default
    size: 'lg',
    font: 'heading',
}

export default {
    baseStyle,
    defaultProps,
};
