const parts = [
    "actions",
    "cardInfo",
    "container",
    "defaultContainer",
    "isDefaultText",
    "makeDefaultButton",
    "methodContents",
    "paymentMethod",
    "paymentMethodInfo",
    "removeButton",
    "updatedAt",
];

const baseStyle = {
    container: {
        flexDir: "column",
        gap: "1rem",
    },
    paymentMethod: {
        alignItems: "center",
        bgColor: "overlayBackground.500",
        border: "1px solid",
        borderColor: "cardBackground.500",
        borderRadius: "base",
        justifyContent: "space-between",
        padding: "1rem",
    },
    paymentMethodInfo: {
        flexDir: "column",
        justifyContent: "center",
        textAlign: ["center", "left"],
    },
    cardInfo: {
        fontSize: "lg",
        fontWeight: "semibold",
        gap: "1rem",
        justifyContent: ["center", "space-between"],
        w: "full"
    },
    methodContents: {
        flexDir: ["column", "row"],
        h: ["7rem", "auto"],
        justifyContent: "space-between",
        w: "full",
    },
    updatedAt: {
        fontSize: "md",
        fontStyle: "italic",
        fontWeight: "thin",
        opacity: 0.7,
    },
    actions: {
        alignItems: "center",
        flexDir: "row",
        gap: "1rem",
        justifyContent: "space-between",
        mt: ["1rem", 0],
    },
    defaultContainer: {
        textAlign: "center",
        w: "10rem"
    },
    makeDefaultButton: {
        fontSize: "base"
    },
    isDefaultText: {
        w: ["7rem", "10rem"],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "0.5rem"
    },
    removeButton: {
        m: 0,
    },
};

export default {
    parts,
    baseStyle,
};
