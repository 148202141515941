import {getColor} from "@chakra-ui/theme-tools";
import tinycolor from "tinycolor2";

/**
 * Function to mimic Foundation's "foreground" function. The key difference is the lightness threshold, being 60%.
 * @param theme - The Chakra theme.
 * @param color - The color to determine the darkness of.
 * @param threshold - The lightness (from 0 to 1) required to consider a background color "light".
 * @returns {boolean}
 */
export function foundationIsDark(theme, color, threshold = 0.6) {
    const colorFromTheme = getColor(theme, color);  // Handle Chakra color keys
    const lightness = tinycolor(colorFromTheme).toHsl().l

    return lightness <= threshold;
}
