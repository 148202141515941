const baseStyle = {
    // Override to fix braintree-drop-in styling that was getting messed up by the chakra css reset rules
    '.braintree-option': {
        borderColor: '#B5B5B5',
    }
}

export default {
    baseStyle,
}
