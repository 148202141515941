import AnvilInputTheme, { getFloatingLabelRaisedTransformRule } from './AnvilInput';

const parts = ['wrapper', 'input', 'label', 'helperText', 'error',];

const {variants: inputVariants} = AnvilInputTheme;

function variantFloatingLabel(props) {
    const { theme } = props;

    // Uses most of the styles from the Input floating-label variant.
    const inputFloatingLabelStyles = inputVariants['floating-label'](props);
    inputFloatingLabelStyles['input']['-webkit-appearance'] = 'none';
    inputFloatingLabelStyles['wrapper']['cursor'] = 'default';

    // Add the up/down arrows
    inputFloatingLabelStyles['wrapper']['&::after'] = {
        bottom: '2',
        content: '"\\25C0 \\25B6"',
        fontSize: 'sm',
        fontFamily: "monospace",
        letterSpacing: 4,
        position: 'absolute',
        right: '2',
        transform: 'rotate(90deg) scaleY(1.25)',
    };

    // Show the label always raised.
    inputFloatingLabelStyles['label']['transform'] = getFloatingLabelRaisedTransformRule(theme);

    return inputFloatingLabelStyles;
}

const variants = {
    'floating-label': variantFloatingLabel,
}

const defaultProps = {
    variant: 'floating-label',
}

export default {
    parts,
    variants,
    defaultProps,
}
