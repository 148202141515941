import colors from './colors';
import layerStyles from './layerStyles';
import sizes from './sizes';
import fonts from './fonts';
import breakpoints from "./breakpoints";

// Import your style changes and add them to this object.
export default {
    colors,
    layerStyles,
    sizes,
    fonts,
    breakpoints,
};
