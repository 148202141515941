// Global style overrides live here
// See https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/styles.ts for what Chakra includes here
// by default.
const styles = {
    global: () => ({
        "a": {
            color: 'link',
        }
    })
}

export default styles;
