import anvilTheme from "./anvilStyles";

export default {
    ...anvilTheme.colors,
    apple: {
        dark: '#1d1d1f',
    },
    'fieldLabel': '#8A8A8A',
    'fieldBorder': '#ccc',
    'cardBorder': '#EEEEEE',
    'asideBorder': '#E1E1E1',
    'link': '#0091ec',
};
