import Clipboard from 'clipboard';
import $ from 'jquery';

//Creates a Clipboard object for elements with the data-copy attribute
$(document).ready(() => {
    let shareLink = new Clipboard("[data-copy]");
    shareLink.on('success', function (e) {
        var inputField = $($(e.trigger).data("clipboardTarget"));
        inputField.foundation();
        $(inputField).foundation('show');
    });
});
