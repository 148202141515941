const parts = ['wrapper', 'input', 'label', 'helperText', 'error',];

const textInputPadding = 0.5;  // In rem
// The padding amount converted to the chakra index
const chakraTextInputPadding = textInputPadding * 4;

/**
 * Get the distance to the middle of the input.
 *
 * @param theme - The chakra theme config
 * @returns {string}
 */
export function getFloatingLabelVariantHalfwayPosition(theme) {
    return `calc((${theme.sizes.formField} / 2) - ${textInputPadding}rem)`;
}

/**
 * Compute the label transformation css rule that needs to be made to raise the label to the top of the field.
 *
 * @param theme - The chakra theme config
 * @returns {string}
 */
export function getFloatingLabelRaisedTransformRule(theme) {
    const textInputHalfwayPosition = getFloatingLabelVariantHalfwayPosition(theme);
    return `scale(0.75) translate(0, calc(-1 * ${textInputHalfwayPosition} + (${textInputPadding}rem / 2)))`;
}

function variantFloatingLabel(props) {
    const { theme } = props;

    // The middle of the input
    const textInputHalfwayPosition = getFloatingLabelVariantHalfwayPosition(theme);
    // Compute the label transformation that needs to be made to raise the label to the top of the field.
    const textInputLabelTransformRaised = getFloatingLabelRaisedTransformRule(theme);

    return {
        wrapper: {
            bg: 'white',
            border: '1px',
            borderColor: 'fieldBorder',
            borderRadius: 'base',
            boxShadow: 'inset 0 1px 1px rgba(0,0,0,0.075)',
            cursor: 'text',
            h: 'formField',
            overflow: 'hidden',
            position: 'relative',
            transition: 'all 500ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
            width: 'full',
            _groupInvalid: {
                borderColor: 'red.500',
            },
            _groupFocus: {
                borderColor: 'blue.500',
            },
            _groupDisabled: {
                bg: '#ddd',
            }
        },
        input: {
            bg: 'transparent',
            appearance: 'none',
            border: 0,
            bottom: 0,
            boxSizing: 'border-box',
            height: 'auto',
            m: 0,
            outline: 'none',
            pt: '8',
            px: chakraTextInputPadding,
            pb: '1',
            position: 'absolute',
            width: 'full',
            _disabled: {
                cursor: 'not-allowed',
                bg: '#ddd',
                borderRadius: 'none',
                opacity: '1'
            }
        },
        label: {
            position: 'absolute',
            color: 'fieldLabel',
            cursor: 'inherit',
            lineHeight: '1',
            left: chakraTextInputPadding,
            top: textInputHalfwayPosition,
            transform: 'scale(1) translate(0,0)',
            transformOrigin: 'left top 0',
            transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
            _disabled: {
                opacity: '1',
            },
            // Input is not blank, raise label
            'input[value]:not([value=""]) ~ &': {
                transform: textInputLabelTransformRaised,
            },
            // Input is in focus, raise label
            'input:focus ~ &': {
                transform: textInputLabelTransformRaised,
            },
            // Input has been autofilled, raise label
            'input:-webkit-autofill ~ &, input:-webkit-autofill:hover ~ &, input:-webkit-autofill:focus ~ &': {
                transform: textInputLabelTransformRaised,
            }
        },
        helperText: {
            bottom: `${chakraTextInputPadding / 2}`,
            color: 'fieldLabel',
            pointerEvents: 'none',
            transition: 'all 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
        },
    }
}

const variants = {
    'floating-label': variantFloatingLabel
};

const defaultProps = {
    variant: 'floating-label',
};

export default {
    parts,
    variants,
    defaultProps,
};