import * as React from "react";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import memoize from "@emotion/memoize";

// Taken from: https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0#gistcomment-2775538
export function hashCode(s) {
    let h;
    for(let i = 0; i < s.length; i++)
          h = Math.imul(31, h) + s.charCodeAt(i) | 0;

    return h;
}

// This was copied and modified from: https://github.com/Andarist/stylis-plugin-extra-scope/pull/11
export function createExtraScopePlugin(...scopes) {
    scopes = scopes.map(scope => `${scope.trim()} `)

    return element => {
        if (element.type !== 'rule') {
            return
        }

        if (element.root?.type === '@keyframes') {
            return
        }

        if (
            !element.parent ||
            (element.props.length === 1 && element.value.charCodeAt(0) !== 58) ||
            !element.length
        ) {
            element.props = element.props.flatMap(prop => {
                    // Don't try and scope rules that are meant to target the root of the page
                    // Needed so that the chakra CSS variables are available everywhere
                    if (prop === ':host' || prop === ':root') {
                        return prop;
                    }
                    return scopes.map(scope => {
                        // Only add the scope if it's not already there
                        if (prop.indexOf(scope) === -1)
                            return scope + prop;
                        return prop;
                    })
                }
            )
        }
    }
}

// Store the hash values of all the css rules that have already been loaded.
let alreadyLoadedStyleRuleHashes = new Set();

/**
 * Stylis plugin that computes a hash of all of the encountered rules and ensures that only one instance of the rule
 * ever gets added to the page.
 *
 * The hash algorithm uses the following fields to determine uniqueness:
 *  - The `value` field from the children rules
 *  - The `value` field from all of the rules parents
 *  - The `value` field from the element itself
 */
export function preventDuplicateRulesPlugin(element) {
    if (element.type !== 'rule') {
        return
    }

    const allChildrenValues = element.children.flatMap((child) => {
        return child.value;
    }).join('');

    const grabAllParentValues = (ele) => {
        if (!ele) {
            return '';
        }
        return ele?.value + grabAllParentValues(ele?.parent);
    }

    // Get the value of all it's parents recursively to include it in the hash calculation
    const allParentValues = grabAllParentValues(element.parent);

    // Hash the rule specifier (element.value) and the value of all it's children together
    // to check for uniqueness
    const childrenHash = hashCode(allParentValues + element.value + allChildrenValues);

    // Found duplicate so clear it's data to prevent it from being added to the page
    if (alreadyLoadedStyleRuleHashes.has(childrenHash)) {
        element.value = '';
        element.children = [];
        element.props = [];
    } else {
        // Add the hash
        alreadyLoadedStyleRuleHashes.add(childrenHash);
    }
}


let memoizedCreateCacheWithScope = memoize((scope) => {
    return createCache({
        key: "chakra",
        stylisPlugins: [
            createExtraScopePlugin(scope),
            preventDuplicateRulesPlugin,
        ]
    });
});

export const ScopeProvider = props => {
    return (
        <CacheProvider value={memoizedCreateCacheWithScope(props.scope)}>
            {props.children}
        </CacheProvider>
    );
};
