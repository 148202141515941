import mutationObserver from 'utils/mutation_observer';
import './loader_buttons'
import $ from 'jquery';

/**
 * data-iframe : data attribute placed on an iframe snippet that responds to breakpoint changes
 *      --  used on the iframe snippet,  element must have a height, width, and src specified
 */
$(document).ready(function () {
    $(window).foundation();  //foundation wasn't running fast enough for the first iframe on the page
    $(document.body).find('[data-iframe]').each((index, element) => updateIframe(element));
    mutationObserver('[data-iframe]', (element) => updateIframe(element));

    $(document.body).find('[data-iframe-srcdoc]').each((index, element) => updateIframe(element, true));
    mutationObserver('[data-iframe-srcdoc]', (element) => updateIframe(element, true));
});

$(window).on('changed.zf.mediaquery', function () {
    $(document.body).find('[data-iframe]').each((index, element) => updateIframe(element));
    $(document.body).find('[data-iframe-srcdoc]').each((index, element) => updateIframe(element, true));
});

/** Updates the source of an iframe snippet based on rules given through a data attribute
 *
 * @param iframe - the iframe to be updated
 * @param useSrcDoc - Whether to switch out the `srcdoc` attribute instead of the default of `src`.
 * */

function updateIframe(iframe, useSrcDoc) {
    let rules;
    let srcAttr = useSrcDoc ? 'srcdoc' : 'src';
    let dataAttr = useSrcDoc ? 'iframeSrcdoc' : 'iframe';
    //getting data attribute information
    rules = $(iframe).data(dataAttr);
    // Convert from base64 to normal
    rules = atob(rules);
    // Convert string to JSON
    rules = JSON.parse(rules);
    // Iterate through each rule, change the iframe source if there is a match
    for (let rule of rules) {
        if (rule.query === Foundation.MediaQuery.current) {
            if (rule.content !== iframe[srcAttr]) {
                //change the iframe src to match the path defined by the query
                iframe[srcAttr] = rule.content;
            }
            iframe.height = rule.height;
            iframe.width = rule.max_width;
        }
    }
}
