import {darken, lighten} from '@chakra-ui/theme-tools';

/**
 * @param color - The base color (will become 500) to lighten and darken into the colorScheme
 * @returns the color scheme object.
 */
export function createColorScheme(color) {
    return {
        50: lighten(color, 50)(),
        100: lighten(color, 40)(),
        200: lighten(color, 30)(),
        300: lighten(color, 20)(),
        400: lighten(color, 10)(),
        500: color,
        600: darken(color, 10)(),
        700: darken(color, 20)(),
        800: darken(color, 30)(),
        900: darken(color, 40)(),
    };
}
