import { extendTheme } from '@chakra-ui/react';

import components from './components';
import foundations from './foundations';
import styles from './styles';

// Our custom theme object with overrides.
export default extendTheme({
    ...foundations,
    components,
    styles,
});