import { transparentize } from "@chakra-ui/theme-tools";
import { createColorScheme, foundationIsDark } from "frontend/theme/utils";

// Multi-component style configuration for the ProductCard
const parts = [
    'cardContainer',
    'specialOffer',
    'subscribeButton',
    'title',
    'pricing',
    'originalPrice',
    'featureList',
    'featureItem',
    'listIcon',
];

// Override for the border size so that it can be consistent between the card and the Special Offer Box
const cardBorderSize = "1px";

function baseStyle(props) {
    const {
        primaryColor,
        hasSpecialOffer,
        specialOfferColor,
        theme
    } = props;

    // If no primaryColor is set, use the anvilTheme primary.
    let primary;
    if (primaryColor !== undefined && primaryColor.length > 0) {
        primary = createColorScheme(primaryColor);
    } else {
        primary = theme.colors.primary;
    }

    // If the card has a specialOfferBg color set, use it. If not, use a transparentized version of the primary color.
    const specialOfferBgColor = !specialOfferColor ? transparentize(primary[500], .75)() : specialOfferColor;
    const specialOfferIsDark = foundationIsDark(theme, specialOfferBgColor);

    return {
        productCardWrapper: {
            maxW: ["xs", "sm"],
            w: "full",
            minW: "xs"
        },
        cardContainer: {
            layerStyle: "card",
            borderWidth: cardBorderSize, // Override the width for consistency with the specialOffer.
            borderTopRadius: hasSpecialOffer ? 0 : "base",
            borderTopWidth: hasSpecialOffer ? 0 : '1px',
            boxSizing: "border-box",
            flexDir: "column",
            fontFamily: "heading",
            justifyContent: "center",
            h: "max-content",
        },
        specialOffer: {
            layerStyle: "card",
            alignItems: "center",
            backgroundClip: "padding-box",
            bgColor: specialOfferBgColor,
            borderColor: specialOfferIsDark ? specialOfferBgColor : "cardBorder",
            borderTopRadius: "base",
            borderBottomRadius: "unset",
            borderWidth: cardBorderSize,
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
            // If there is no special offer then we remove the special offer bar on mobile, but
            // leave it invisible for medium and up, if there is a special offer then we always display it as flex
            display: !hasSpecialOffer ? ['none', null, 'flex'] : 'flex',
            fontSize: "lg",
            fontWeight: "bold",
            justifyContent: "center",
            p: 2,
            textAlign: "center",
            textColor: specialOfferIsDark ? "white" : "black",
            visibility: hasSpecialOffer ? 'initial' : 'hidden',
        },
        specialOfferText: {
            _empty: {
                _after: {
                    content: '"."',
                    visibility: 'hidden',
                }
            }
        },
        subscribeButton: {
            mb: 4,
            w: "full",
        },
        title: {
            fontSize: "2xl",
            fontWeight: "medium",
            textAlign: "center",
        },
        pricing: {
            alignItems: "center",
            flexDir: "row",
            justifyContent: "center",
            mt: 7,
            fontSize: "2xl",
            fontWeight: "bold",
            height: "1.6rem",
        },
        originalPrice: {
            fontSize: "2xl",
            fontWeight: "normal",
            mr: 2,
            opacity: 0.65,
            textDecor: "line-through",
        },
        pricingPeriod: {
            alignItems: "center",
            justifyContent: "center",
            mb: 2,
            _empty: {
                _after: {
                    content: '"."',
                    visibility: 'hidden',
                }
            }
        },
        extraProductText: {
            mt: 1,
            mb: 3,
        },
        listIcon: {
            color: "black",
            height: 6, // Same as the lineHeight
        },
        featureList: {
            listStyleType: "none",
            ml: 0,
        },
        featureItem: {
            display: 'flex',
            fontFamily: "body",
            lineHeight: 6,
            mt: 2,
        }
    };
}

export default {
    parts,
    baseStyle,
};
