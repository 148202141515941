// Styling for the RichText component. Chakra component styles will also apply if not defined here.
export default {
    baseStyle: {
        "&": {
            "p": {
                mb: 4,
                ":last-child": {mb: 0},
            },
            "h1, h2, h3, h4, h5, h6": {mb: 2},
            "hr": {my: 5},
        }
    }
};
