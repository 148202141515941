import mutationObserver from 'utils/mutation_observer';
import $ from 'jquery';

/**
 * data-anvil-loading-button : data attribute placed on buttons that disable and display loaders on click
 *      --  use on button with class .anvil-base-button, this button should wrap a span with an .anvil-loader
 *          class if a loader is to be included. examples in order tunnel
 **/
$(document).ready(function () {
    //searches for any buttons initially loaded on the page
    $(document.body).find('[data-anvil-loading-button]').each((index, element) => updateButton(element));

    //checks for any buttons that are inserted into the page
    mutationObserver('[data-anvil-loading-button]', updateButton, {childList: true, subtree: true}, true);
});

/** Disables the button and displays a loading icon while the next page loads.
 *
 * @param button - the button clicked that triggers this function
 */
function updateButton(button) {
    let $button = $(button);
    let $form = $button.closest('form');

    // if the form has invalid inputs, don't let the button go into loading mode
    $('input', $form).on('invalid', function () {
        $button.removeClass('anvil-base-button--loading');
        $button.children('.anvil-loader').removeClass('anvil-loader--visible');
    });

    $button.on('click', function (e, waited) {
        // Safari on ios won't apply the CSS class if it is submitted immediately.
        if (waited === true) return;

        $button.addClass('anvil-base-button--loading');
        $button.children('.anvil-loader').addClass('anvil-loader--visible');

        setTimeout(function () {
            $button.trigger('click', true);
        }, 100);
        return false;
    });
}