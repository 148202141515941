
// Custom variant based on the Alert `solid` variant.
function variantSolidRounded(props) {
    const {theme} = props;
    const solidVariantStyles = theme.components.Alert.variants.solid(props);
    return {
        ...solidVariantStyles,
        container: {
            ...solidVariantStyles.container,
            borderRadius: 'base',
        }
    };
}


const variants = {
    'solid-rounded': variantSolidRounded,
};

const sizes = {
    md: {
        icon: {
            h: 6,
            w: 5,
        },
        title: {
            lineHeight: 6,
        },
        description: {
            lineHeight: 6,
        },
    },
    lg: {
        container: {
            fontSize: 'xl',
        },
        icon: {
            h: 8,
            w: 7,
        },
        title: {
            lineHeight: 8,
        },
        description: {
            lineHeight: 8,
        }
    }
}

const defaultProps = {
    variant: 'solid-rounded',
    size: 'md',
}

export default {
    sizes,
    variants,
    defaultProps,
};
